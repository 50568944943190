@import "../../../../_var.scss";

.EditWineft {
  .logs-controller {
    padding-right: 30px;

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      h3,
      .logs-toggler {
        margin-bottom: 15px;
      }

      .logs-toggler {
        height: 32px;
        width: 32px;
        display: block;
        border-radius: 50%;
        border: 1px solid $borderColor2;
        background-image: url(../../../../Assets/image/svgimages/eye-close.svg);
        background-repeat: no-repeat;
        background-size: auto;
        background-position: center;
        transition: 0.3s all ease-in-out;

        &.active {
          background-image: url(../../../../Assets/image/svgimages/eye-open.svg);
          background-color: $borderColor2;
        }
      }
    }

    h4 {
      font-size: 16px;
      color: $grey40;
    }

    ul {
      padding: 0;

      li {
        flex-direction: column;
        padding: 16px 10px;
        margin: 0;

        >span {
          color: $greyLight;
          font-size: 12px;
          display: block;

          span {
            color: $lightBlue;
            padding-left: 10px;
          }
        }

        p {
          position: relative;
          padding-left: 15px;
          color: $darkText;
          font-size: 16px;
          font-weight: 500;
          padding-top: 5px;
          max-width: 289px;
          overflow: hidden;
          width: 100%;

          &:before {
            content: "";
            height: 8px;
            width: 8px;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: 0;
            top: 13px;
          }

          &.byadmin {
            &::before {
              background-color: $navTextHoverColor;
            }
          }

          &.bymaker {
            &::before {
              background-color: $greydark;
            }
          }
        }

        &:not(:last-child) {
          border-bottom: 1px solid $borderColor2;
        }
      }

      .active {
        border: none !important;
        padding: 0;

        .bymaker {
          border-bottom: 1px solid $borderColor2;
          margin: 0;
          padding: 6px 0 16px 15px;
        }
      }
    }
  }

  .Admin_By {
    background: #93204029 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 16px 10px;
  }

  .User_By {
    background: #908bb936 0% 0% no-repeat padding-box;
    width: 100%;
    padding: 16px 10px;
  }

  .yesterday {
    .yestCode {
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #ededed;
      border-radius: 8px;

      li {
        cursor: pointer;

        textarea {
          border: 1px solid #e7e7e7;
          width: 100%;

          &:focus-visible {
            border: 1px solid #e7e7e7;
            outline: none;
          }
        }

        .btnadd {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          margin-top: 10px;

          button {
            background: transparent;
            margin-left: 10px;
            border: 1px solid #e7e7e7;
            border-radius: 5px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            img {
              margin-right: 10px;
            }
          }
        }
      }

      .hiddinFile {
        display: none;
      }

      .FileShow {
        display: block;
        width: 100%;
      }
    }
  }

  .right,
  .left {
    .top-controll {
      max-width: 540px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 991px) {
        max-width: 100%;
      }

      .publicprev {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 500;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        transition: 0.3s all ease-in-out;
        color: $darkText2;
        border: 1px solid $borderColor2;

        svg {
          margin-right: 10px;
          vertical-align: middle;
        }

        &:hover {
          border-color: $borderColor;
        }

        &.disabled {
          opacity: 0.3;
          pointer-events: none;
        }
      }

      >a {
        margin-bottom: 15px;
      }
    }

    .crtbtl-left {
      .HeadingSpace {
        margin-bottom: 20px;
      }

      .BtnList {
        margin-bottom: 40px;
        display: flex;
        align-items: center;

        .ListedValue {
          min-width: 65px;
          background: #6dbb45 0% 0% no-repeat padding-box;
          border: 1px solid #ededed;
          border-radius: 8px;
          min-height: 32px;
          color: #ffffff;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  @media (min-width: 992px) {
    >.left {
      border-right: 1px solid transparent;

      .top-controll {
        display: none;
      }

      &.brdr {
        border-right: 1px solid $borderColor2;
      }
    }
  }

  @media (max-width: 991px) {
    flex-wrap: wrap;

    .right {
      padding-left: 0;

      .top-controll {
        display: none;
      }
    }

    >.left,
    >.right {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .left {
      border: 0;
      border-bottom: 1px solid $borderColor2;
      margin-bottom: 25px;

      .logs-controller {
        justify-content: space-between;
        padding-right: 0;

        .bottom {
          .ant-row {
            justify-content: space-between;

            >.ant-col {
              flex: 0 0 45%;
              max-width: 45%;
            }
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .left {
      .logs-controller {
        .bottom {
          .ant-row {
            >.ant-col {
              flex: 0 0 100%;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .sectionInfo {
    position: relative;

    .InfoToggle {
      position: absolute;
      top: 0;
      right: -12px;
      left: auto;
    }
  }
}

.nftwine {
  &::before {
    content: "";
    height: 64px;
    width: 64px;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    background-color: $white;
    background-image: url(../../../../Assets/image/image_Icon.svg) !important;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    border: 1px solid $borderlight;
    z-index: 1;
  }
}

.httpInput {
  margin-top: 32px;

  .ant-input-group-wrapper {
    .ant-input-group {
      height: 40px;
      border-bottom: 1px solid $borderColor2;

      .ant-input-group-addon {
        border: none;
        padding: 0;
        background: transparent;
      }

      .ant-input {
        border: none;
        background: transparent;
      }
    }
  }
}

.bottlefirst {
  margin-bottom: 32px;

  h4 {
    font-size: 16px;
    font-weight: 600;
    color: $bottText;
    margin: 0;
  }
}

.boxCheck {
  margin-bottom: 32px;

  .checkbox--style {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;

    label {
      display: flex;
      align-items: center;
      height: 24px;
      padding-left: 40px;
    }
  }

  p {
    padding-left: 40px;
    color: $color-67;
    font-size: 10px;
  }
}

.RadioBtn {
  margin: 0 auto 32px;

  .radiolabel {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .btnRadio {
    margin-right: 36px;

    label {
      font-size: 16px;
      color: $blackfade !important;
    }
  }

  .RadioInfo {
    display: flex;
    align-items: center;

    .InfoToggle {
      width: 46px;
      height: 40px;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
    }
  }
}

.priceFiled {
  padding-left: 15px;

  @media (max-width: 767px) {
    padding: 0;
  }

  p {
    font-size: 12px;
    margin-bottom: 25px;
    color: $grey;

    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  h3 {
    font-size: 16px;
    font-weight: 700;

    @media (max-width: 1565px) {
      font-size: 12px;
      font-weight: 400;
    }

    @media (max-width: 767px) {
      margin-bottom: 32px;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.perameter_Inputs {
  max-width: 450px;
  width: 100%;
  margin-bottom: 32px;
}

.imageUpload {
  p {
    margin-top: 8px;
  }
}

.AddPhotos {
  margin-bottom: 20px;

  button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 160px;
    height: 34px;
    cursor: pointer;
  }
}

.InputPresent {
  display: flex;
  align-items: flex-end;
  width: 100%;
  max-width: 450px;
  margin-bottom: 32px;

  .InfoInput {
    margin-bottom: 0;
    margin-right: 1rem;
  }

  .Sublabel {
    margin-bottom: 12px;
    color: #130207;
    font-weight: 700;
    font-size: 12px;
  }
}

.NftCreate {
  border: none !important;
  margin: 0 !important;

  select:disabled {
    cursor: no-drop;
  }

  button:disabled {
    cursor: no-drop;
    opacity: .45;
  }

  .ui.disabled.input,
  .ui.input:not(.disabled) input[disabled] {
    cursor: no-drop;
  }
}

.BtnListing {
  .borderBtn.addNew_Btn {
    cursor: pointer;
    min-width: 100px;
    height: 50px !important;
    background: $white;
    border: 1px solid $borderColor2;
    border-radius: 5px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    color: $darkText2;
    text-transform: capitalize;
    padding: 8px 16px;
    transition: all 0.25s;
    margin-right: 10px;

    &:hover {
      border-color: $darkButton;
    }

    img {
      margin-right: 16px;
    }
  }

  .saveBtn2 {
    min-width: 100px;
    height: 50px !important;
  }
}

.SendBtn {
  min-width: 160px;
  height: 50px !important;
  margin: 0;
}

.BtnPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  button {
    background: #ffffff 0% 0% no-repeat padding-box;
    cursor: pointer;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 149px;
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    color: #21040c;
  }
}

.m-0 {
  margin: 0 !important;
}

.BtnIn_Edit {
  display: flex;
  justify-content: space-between;

  @media (max-device-width: 396px) {
    flex-wrap: wrap;

    .BtnListing {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      .Listspace {
        width: 100%;
      }
    }

    .ApprovedBtn {
      width: 100%;

      .Editspace {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .BtnListing {
    @media (min-device-width: 1366px) and (max-device-width: 1460px) {
      .Listspace {
        min-width: 80px !important;
        padding: 0 10px;
      }
    }
  }

  .ApprovedBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .Editspace {
      min-width: 100px;
      padding: 0 10px !important;
    }
  }
}

.wineNFTHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 80px;
}

.btnInTranslationMode {
  justify-content: flex-end;
}

.translationBtnWrapper {
  display: flex;
  gap: 16px;
}