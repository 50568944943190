.btnConfi {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
}

.publishBtn {
  width: 171px;
  height: 50px !important;
  background: transparent !important;
  color: #000 !important;
  border: 1px solid #e7e7e7 !important;

  &:hover {
    background: #6dbb45 !important;
    color: #fff !important;
    border: 1px solid #6dbb45 !important;
  }
}

.UnpublishBtn {
  width: 171px;
  height: 50px !important;
  background: transparent !important;
  color: #000 !important;
  border: 1px solid #e7e7e7 !important;

  &:hover {
    background: #932040 !important;
    color: #fff !important;
    border: 1px solid #932040 !important;
  }
}

.bottleHeader {
  display: flex;
  justify-content: space-between;
}

.bottleBtnWrapper {
  display: flex;
  align-self: flex-end;
  gap: 16px;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}