.wrapper {
  position: relative;
  padding-top: 80px;
}

.languageSelectorWrapper {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.languageSelector {

  [class*=cus_drop] {


    select {
      border-bottom: unset;
      padding-top: 4px;
      padding-bottom: 8px;
    }
  }
}